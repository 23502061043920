<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <ValidationProvider name="Return Type" vid="type" rules="required|min_value:1" v-slot="{ errors }">
                  <b-form-group label-for="type">
                      <template v-slot:label>
                          {{ $t('teaGarden.type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-radio-group v-model="search.type"
                          :options="garrdenFactoryTypeList"
                          value-field="value" text-field="text"
                          :state="errors[0] ? false : (valid ? true : null)">
                      </b-form-radio-group>
                      <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3" v-if="search.type === 3">
            <ValidationProvider name="Factory" vid="factory_search_id">
                <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="factory_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('teaGarden.bought_leaf_factory') }}
                </template>
                <v-select name="factory_search_id"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    v-model="search2.factory_search_id"
                    label="text"
                    option-value="value"
                    :options= factoryList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3" v-else>
            <ValidationProvider name="Garden" vid="garden_search_id">
                <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="garden_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('teaGardenConfig.select_garden')}}
                </template>
                <v-select name="garden_search_id"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    v-model="search2.garden_search_id"
                    label="text"
                    option-value="value"
                    :options= gardenList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
         <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-group
                label-for="from_date"
                label-cols-sm="12"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.start_date') }}
                </template>
                <date-picker
                  id="from_date"
                  v-model="search.from_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-group
                label-cols-sm="12"
                label-for="to_date">
                <template v-slot:label>
                  {{ $t('globalTrans.to_date') }}
                </template>
                <date-picker
                  id="to_date"
                  v-model="search.to_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col sm="12" class="text-right">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.stock_in_out') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col class="text-right">
                <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                </b-button>
            </b-col>
            <b-col md="12" class="table-responsive">
                <b-table-simple striped bordered small>
                  <thead>
                    <tr>
                      <th width="10%">{{ $t('globalTrans.sl_no') }}</th>
                      <th width="60%">{{ $t('globalTrans.date') }}&nbsp;({{ $t('teaGarden.stock_in_out') }})</th>
                      <th width="30%">{{ $t('teaGarden.stock_in_out_qty') }} ({{ $i18n.locale === 'en' ? 'Kg' : 'কেজি' }})</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(detail,index) in listDatax" :key="index">
                      <td class="text-center">{{ $n(index + 1) }}</td>
                      <td>{{ detail.stock_date | dateFormat }}
                        <span v-if="detail.stock_type === 1">({{ $t('teaGarden.in') }})</span>
                        <span v-if="detail.stock_type === 2">({{ $t('teaGarden.out') }})</span>
                      </td>
                      <td class="text-right">
                        <span v-if="detail.stock_type === 1">{{ $n(detail.stock_in_quantity) }}</span>
                        <span v-if="detail.stock_type === 2">{{ $n(detail.stock_out_quantity) }}</span>
                        &nbsp; &nbsp; &nbsp;
                      </td>
                    </tr>
                      <tr>
                        <td></td>
                        <td class="text-right"><b>{{ $t('teaGarden.current_available_stock') }} <span v-if="search.to_date">({{ search.to_date | dateFormat }})</span><span v-else>({{ getTodayDate() | dateFormat }})</span></b></td>
                        <td class="text-right" v-if="listDatax.length > 0"><b>{{ $n(getTotal()) }} {{ $i18n.locale === 'en' ? 'Kg' : 'কেজি' }}</b></td>
                      </tr>
                  </tbody>
                </b-table-simple>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { gardenStockInOutReportList } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    Form
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      valid: null,
      search2: {
        garden_search_id: '',
        factory_search_id: ''
      },
      listDatax: [],
      search: {
        type: 1,
        garden_id: '',
        factory_id: '',
        from_date: '',
        to_date: ''
      }
    }
  },
  created () {
    if (this.isGardenAdminCheckGardenId() > 0) {
      this.search2.garden_search_id = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(el => el.value === this.isGardenAdminCheckGardenId())
    }
    if (this.isFactoryAdminCheckFactoryId() > 0) {
      this.search2.factory_search_id = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(el => el.value === this.isFactoryAdminCheckFactoryId())
    }
    this.loadData()
  },
  watch: {
    'search2.garden_search_id': function () {
      if (this.search2.garden_search_id.value > 0) {
        this.search.garden_id = this.search2.garden_search_id.value
      }
    },
    'search2.factory_search_id': function () {
      if (this.search2.factory_search_id.value > 0) {
        this.search.garden_id = this.search2.factory_search_id.value
      }
    },
    currentLocale: function (newVal) {
      this.factoryList = this.factoryList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
      this.gardenList = this.gardenList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
      if (this.search2.garden_search_id) {
        const gardenObj = this.search2.garden_search_id
        this.search2.garden_search_id = Object.assign({}, gardenObj, { text: this.currentLocale === 'en' ? gardenObj.text_en : gardenObj.text_bn })
      }
      if (this.search2.factory_search_id) {
        const factoryObj = this.search2.factory_search_id
        this.search2.factory_search_id = Object.assign({}, factoryObj, { text: this.currentLocale === 'en' ? factoryObj.text_en : factoryObj.text_bn })
      }
    }
  },
  computed: {
    garrdenFactoryTypeList () {
        let filteredList = this.$store.state.TeaGardenService.commonObj.garrdenFactoryTypeList
        if (this.isGardenAdminCheckGardenId() > 0) {
           filteredList = filteredList.filter(item => item.value !== 3)
        }
        if (this.isFactoryAdminCheckFactoryId() > 0) {
           filteredList = filteredList.filter(item => item.value > 2)
        }
        return filteredList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    factoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    formTitle () {
      return this.$t('teaGarden.stock_in_out') + ' ' + this.$t('globalTrans.entry')
    }
  },
  methods: {
    async pdfExport () {
        const params = Object.assign({
          request_type: 'pdf',
          local: this.$i18n.locale,
          org_id: 5,
          type: this.search.type,
          garden_id: this.search.garden_id,
          factory_id: this.search.factory_id,
          from_date: this.search.from_date,
          to_date: this.search.to_date
        })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, gardenStockInOutReportList, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.loader = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getTodayDate () {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const currentDate = `${year}-${month}-${day}`
      return currentDate
    },
    getGardenName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
    getFactoryName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
    edit (item) {
      this.editItemId = item.id
    },
    getTotal () {
      let total = 0
      if (this.listDatax.length > 0) {
        this.listDatax.map(item => {
            total += item.stock_quantity
        })
      }
      return total
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search2.garden_search_id) {
        if (this.search2.garden_search_id.value > 0) {
          this.search.garden_id = this.search2.garden_search_id.value
          this.search.factory_id = 0
        }
      } else {
        this.search.garden_id = 0
      }
      if (this.search2.factory_search_id) {
        if (this.search2.factory_search_id.value > 0) {
          this.search.factory_id = this.search2.factory_search_id.value
          this.search.garden_id = 0
        }
      } else {
        this.search.factory_id = 0
      }
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, gardenStockInOutReportList, params)
      if (result.success) {
        this.listDatax = result.data
      } else {
        this.listDatax = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getHeadAccountTypeName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.headAccountType.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getEmployeeTypeNames (arrData) {
      var data = ''
      arrData.forEach((element, index) => {
       const obj = this.$store.state.TeaGardenService.commonObj.employeeType.find(item => item.value === element)
        if (obj) {
          this.currentLocale === 'en' ? data += obj.text_en : data += obj.text_bn
        }
        if (arrData[index + 1]) {
          data += ','
        }
      })
      return data
    }
  }
}
</script>
